
.home {
    display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
    text-align: center;
}
img {
  max-width: 50%;
  width: 80%; /* Adjust this value as needed */
}

p {
  margin-top: 20px; /* Adjust margin as needed */
  width: 90%; /* Use percentage for responsive width */
  font-size: 1em; /* Adjust base font size as needed */
}

@media(max-width: 768px) {
  img {
    width: 95%; /* Adjust this value as needed */
  }

  p {
    font-size: 0.9em; /* Adjust base font size as needed */
    width: 95%;
  }
}
