@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

/* DISPLAY FOR PROJECT DETAIL PAGE
********************************************************************
*/
.details {
  width: 80%;
  margin: 0 auto;
  margin-top: 75px;
  font-family: 'Roboto Mono', monospace;
  margin-bottom: 150px;
  color: #353a5d;
  font-size: 2rem;
}

.project-detail-title {
    font-size: 1.5em;
    font-weight: 700;
    font-family: 'Roboto Mono', monospace;
    max-width: 90%;
    /*margin-right: 1em;*/
}

.project-detail-section-header {
    margin-top: 1em;
    font-size: 1.3em;
}

.project-detail-section-content {
    font-family: 'montserrat', sans-serif;
    font-weight: 500;
    font-size: .7em;
}
